<template>
    <div class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>

        <v-row no-gutters>

            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="entities"
                    :item-class="itemRowBackground"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })"
                    show-expand
                    @item-expanded="itemExpanded"
                    :expanded.sync="expanded"
                    >

                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
                        <v-btn 
                            icon
                            x-small
                            plain
                            class="expand-cust-btn"
                            @click="expand(true)" 
                            v-if="!isExpanded"
                        >
                            <v-icon>
                                fas fa-caret-right
                            </v-icon>
                        </v-btn>
                        <v-btn 
                            icon
                            x-small
                            plain
                            class="expand-cust-btn"
                            @click="expand(false)" 
                            v-else
                        >
                            <v-icon>
                                fas fa-caret-down
                            </v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <div class="table-expanded-wrapper">

                                <template v-if="item._info">
                                    <v-treeview 
                                        dense
                                        :items="item._info"
                                        class="cust-treeview-in-list"
                                        >
                                        <template v-slot:label="{ item }">

                                            <template v-if="item.text=='Link'">
                                                    
                                                <div class="d-flex align-center">
                                                    <span class="linked-docs-title">{{ $t(GetLinkedTypes[item.type]) }}</span>
                                                    <div class="onlyReadData more-per-lab-wrap">
                                                        <v-doc-chip
                                                            v-for="(doc, index) in item.values"
                                                            :key="item.type+index"
                                                            :id="doc.Values[6]"
                                                            :link-id="doc.Values[0]"
                                                            :name="$t(GetDocumentTypes[doc.Values[9]]) + ' № ' + doc.Values[2] + ` ${$t('от_время')} ` + doc.Values[3] + (doc.Values[5] ? ' (' + doc.Values[5] + ')' : '')" 
                                                            iconColor="#7289DA"
                                                            :is-linked-document="true"
                                                            :source-document-id="item.sourceDocumentId"
                                                        />
                                                    </div>
                                                </div>

                                            </template>
                                            <template v-else-if="item.text=='Notify'">
                                                <div class="d-flex ctil-row-wrap">
                                                    <div class="flex-grow-1">
                                                        <div class="elipsis-text-wrap">
                                                            <span>
                                                                {{ $t("Уведомление") }}
                                                            </span>
                                                            <span>
                                                                {{ item.Values[0] }}
                                                            </span>
                                                            <span>
                                                                <v-employee-chip :id="item.Values[3]" :name="item.Values[2]" /> 
                                                            </span>
                                                            <div class="etw-text-container" v-tooltip.top-center="item.Values[1]">
                                                                <span class="etw-text">
                                                                    {{ item.Values[1] }}
                                                                </span>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>                                           
                                            </template>
                                            <template v-else>
                                                {{ getLabel(item) }}
                                            </template>
                                            
                                        </template>
                                    </v-treeview>
                                </template>
                                <v-progress-linear v-else
                                    color="#a94442"
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>

                            </div>
                        </td>
                    </template>

                    <template v-slot:[`item.regdate`]="{ item }">
                        <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                    </template>

                    <template v-slot:[`item.correspondent`]="{ item }">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.correspondent">
                                {{item.correspondent}}
                            </div>
                        </div>
                    </template>
                    
                    <template v-slot:[`item.theme`]="{ item }">
                        <div class="elipsis-text-wrap">

                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>

                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.theme">
                                    {{ item.theme }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.authorname`]="{ item }">
                        <div class="elipsis-text-wrap">
                            <v-employee-chip :id="item.authorid" :name="item.authorname" />
                        </div>
                    </template>

                    <template v-slot:[`item.signername`]="{ item }">
                        <div class="elipsis-text-wrap">
                            <v-employee-chip v-if="item.signername" :id="item.signeremployeeid" :name="item.signername" />
                        </div>
                    </template>

                    <template v-slot:[`item.sendstatus`]="{ item }">
                        <StatusChip :status="avaibleStatuses[$options.statusKeys[item.sendstatus]]"/>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>

        <!-- модалка -->
        <OutgoingExFilterDlg
            v-model="extendedTableFilter"
            ref="ExFilterDlgRef"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import sys from "@/services/system";
import adapter from "@/services/adapter";
import { mapGetters, mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import OutgoingExFilterDlg from '@/components/documents/dialogs/extended-filters/Outgoings.vue';

import i18n from '@/i18n';


export default {
    statusKeys: ["outgoingNotSended", "outgoingSended", "outgoingSendedButRejected"],
    columnIndex: {
        'regnumber': 2,
        'regdate': 4,
        'correspondent': 5,
        'theme': 6,
        'authorname': 12
    },
    outgoingDocumentTypes: {
        "AllOutgoings": -1,
        "Initiative": 0,
        "Answer": 1,
        "EuolAnswer": 2
    },
    name: "CorrespondenceOutgoings",
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        ExtendedFilterPanel,
        StatusChip,
        OutgoingExFilterDlg
    },
    computed : {
        ...mapGetters('documents/references',  { GetLinkedTypes: 'GetLinkedTypes', GetDocumentTypes: 'GetDocumentTypes', avaibleStatuses: 'GetAvaibleStatuses' }),
        headers() {
            return [
                { text: this.$t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: this.$t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: this.$t("Корреспондент"), value: 'correspondent', width: '15%' },
                { text: this.$t("Краткое_содержание"), value: 'theme'},
                { text: this.$t("Автор"), value: 'authorname', width: '150px'},
                { text: this.$t("Подписал"), value: 'signername', width: '150px'},
                { text: this.$t("Статус"), value: 'sendstatus', width: '150px', sortable: false }
            ];
        },
        status: {
            get: function() {
                return this.$store.getters['correspondence/outgoings/getStatus'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('correspondence/outgoings/SET_STATUS', newValue);
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['correspondence/outgoings/getSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('correspondence/outgoings/SET_SEARCH', v);
            }, 500)
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['correspondence/outgoings/getExtendedFilter'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('correspondence/outgoings/SET_EXTENDED_FILTER', newValue);
                }
            }
        },
        options: {
            get: function() {
                return this.$store.getters['correspondence/outgoings/getTableOptions'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('correspondence/outgoings/SET_TABLE_OPTION', newValue);
                }
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                OutgoingDocumentType: this.$options.outgoingDocumentTypes[this.status],
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
    },
    data() {
        return {
            collection: "Outgoings",
            filterDataSource: null,
            urls: [],

            loading: false,
            total: 0,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,

            expanded: [],
            open: {},
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        dateFormat: sys.dateFormat,
        getDaysDiff: sys.getDaysDiff,
        executerStatusColor: sys.getExecuterStatusColor,
        itemRowBackground: (item) => item.readed === true ? '' : 'font-weight-bold',
        getLabel: (item) => item.Values.toString(),
        onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.loadCollectionData();
            await this.getData(true);
        },
        async Export() {
            let filter = this.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;

            let exportURI = `api/download/export?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.Token}`;
                
            let text = i18n.t('Экспорт_списка_исходящих_документов_от_formatedDateTime.message', { formatedDateTime: this.$moment().format('DD.MM.YYYY HH.mm.ss') });

            this.$eventBus.$emit('on-get-report', { url: exportURI, text: text, type: 'xls' });
        },
        async onFilterClick() {
            try
            {
                await this.$refs.ExFilterDlgRef.open();
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async loadCollectionData() {
            this.setOverlayVisible({ visible: true });

            try
            {
                let response = await httpAPI({
                    url: `api/actions/collection?type=Documents.Correspondence`,
                    method: 'GET'
                });

                if (response) {
                    await this.loadFilters();
                    this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
                }
            }
            finally
            {
                this.setOverlayVisible({ visible: false });
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `api/correspondence/filter?collection=${this.collection}&configuration=8`,
                method: 'GET'
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['global/auth/getUserInfo'])?.Permissions ?? [];
                var filter = filterResponse.data.Payload.Filter.Items?.find(x => x.Value == this.collection);

                if (filter && filter.Items) {
                    filter.Items.Items = filter.Items.Items?.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                    this.filterDataSource = { Filter: filter.Items };
                }
            }
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, OutgoingDocumentType, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;

            return { 
                SearchStr,
                OutgoingDocumentType,
                DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ...extendedFilterData
            };
        },
        async getData(isRefresh = false) {
            this.$eventBus.$emit('update_correspondents_counters');
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            
            let response = await httpAPI({
                url: `api/correspondence/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET'
            });
            
            this.open = {};
            this.expanded = [];

            this.entities = response.data?.Payload?.data ?? [];
            this.total = response.data?.Payload?.total ?? 0;

            this.loading = false;      
        },
        async oneStatusColWidth() {
            var parent = document.getElementsByClassName('content-box')[0];
            var status_col_obj = parent.getElementsByClassName('status-col');
            var status_dropdown_col_obj = parent.getElementsByClassName('status-dropdown-col');
            var status_col_w = status_col_obj.length > 0 ? status_col_obj[0].clientWidth : 0;

            if (status_dropdown_col_obj.length > 0) {
                for (var index = 0; index < status_dropdown_col_obj.length; index++) {
                    status_dropdown_col_obj[index].style.width = status_col_w + "px";
                }
            }
        },
        async itemExpanded(val) {
            if (!val.value) return;
            
            this.$set(val.item, '_info', null)

            let response = await httpAPI({
                url: `api/correspondence/getdocumentchildren?id=${val.item.id}`,
                method: 'GET',
                headers: { 'isCommon': val.item.iscommon }
            });
            
            let data = response?.data?.Payload?.Data?.Object?.data;
            
            if (data)
            {
                let treeData = adapter.transformTreeData(null, data, val.item.id);
                this.$set(val.item, '_info', treeData)
            }   
        },
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
        open: {
            handler() {
                this.$nextTick(this.oneStatusColWidth);
            },
            deep: true,
        }
    },
    mounted() {
        this.$nextTick(this.oneStatusColWidth);
        window.addEventListener('resize', this.oneStatusColWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.oneStatusColWidth);
        this.urls.forEach(url => URL.revokeObjectURL(url));
    },
    beforeRouteEnter(to, from, next) {
        next(async vm =>
        {
            await vm.loadCollectionData();
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await this.loadCollectionData();
        next();
    },
}
</script>